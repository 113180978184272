import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import ReactSelect from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

import styles from "./SelectField.module.scss";

import { Control, IndicatorsContainer, Menu, Option } from "./components";

type Option = Record<string, string>;

type SelectFieldProps = UseControllerProps & StateManagerProps;

const SelectField: React.FC<SelectFieldProps> = props => {
  const { name, control, ...rest } = props;
  const { field } = useController({ name, control });
  return (
    <ReactSelect
      unstyled
      {...field}
      {...rest}
      className={styles.select}
      classNamePrefix={styles.select}
      classNames={{
        multiValue: () => styles.multiValue,
        valueContainer: props =>
          props.isMulti ? styles.multiValueContainer : undefined,
        ...rest.classNames
      }}
      components={{
        IndicatorSeparator: () => null,
        Control,
        Menu,
        Option,
        IndicatorsContainer,
        ...rest.components
      }}
    />
  );
};

export default SelectField;
