import axios from "axios";

import { oktaAuth } from "../lib/oktaAuth";
import { trimObject } from "../utils/objects";

/**
 * Main axios instance
 */
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});

axiosInstance.interceptors.request.use(config => {
  const accessToken = oktaAuth.getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  const email =
    localStorage.getItem("impersonateEmail") ??
    localStorage.getItem("profileEmail");
  if (email) {
    config.headers["X-Email"] = email;
  }
  // Trim data recursively. Exclude this from tests because of issues with axios-mock-adapter.
  if (config.data && process.env.NODE_ENV !== "test") {
    config.data = trimObject(config.data);
  }
  return config;
});

export { axiosInstance };
