import React from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";

import styles from "./AnnouncementModal.module.scss";

import Condition from "../../../../components/shared/Condition";
import Icons from "../../../../components/shared/Icon";
import Button from "../../../../components/userManagement/WCMButton";
import {
  delAlertPreviewItems,
  setAlertPreviewItems
} from "../../../../containers/AlertBanner";
import { changeTimeZone } from "../../../../utils/time";
import { correctUrl } from "../../../../utils/url";
import { AnnouncementFormValues } from "../../Announcements.types";
import { reviewDateFormat } from "../../constants";
import { deriveStatus } from "../../utils";
import Duration from "../Duration";
import Status from "../Status";

interface AnnouncementReviewProps {
  setIsReview: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  isEnding?: boolean;
}

const AnnouncementReview: React.FC<AnnouncementReviewProps> = ({
  setIsReview,
  isEnding,
  isEditing
}) => {
  const { getValues } = useFormContext<AnnouncementFormValues>();

  const {
    dealSigningTerritories,
    startDate,
    endDate,
    timeZone,
    message,
    href,
    link
  } = getValues();

  const formattedStartTime = changeTimeZone(timeZone.value, startDate).toFormat(
    reviewDateFormat
  );
  const formattedEndTime = changeTimeZone(timeZone.value, endDate).toFormat(
    reviewDateFormat
  );

  const status = deriveStatus(
    startDate.toISOString(),
    endDate.toISOString(),
    timeZone.value
  );
  const handlePreview = () => {
    delAlertPreviewItems();
    setAlertPreviewItems([
      {
        id: "0",
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        text: message,
        link: href && link ? { href, text: link } : undefined
      }
    ]);
    window.open("/alert-preview", "_blank");
  };

  return (
    <div className={styles.announcementReview}>
      <h1 className={styles.title}>
        <Condition
          condition={isEnding || isEditing}
          Truthy={isEnding ? "End Announcement" : "Review Updated Announcement"}
          Falsy={"Review New Announcement"}
        />
      </h1>
      <section className={styles.reviewHeader}>
        <h2 className={styles.subtitle}>System Announcement</h2>
        <div className={styles.announcementButtonGroup}>
          <Condition
            condition={!isEnding}
            Truthy={
              <Button
                onClick={() => {
                  setIsReview(false);
                }}
                className={styles.announcementButton}
                variant="text"
                preIcon={{ kind: "edit_outlined" }}
                size="small"
              >
                Make Changes
              </Button>
            }
          />
          <Button
            onClick={handlePreview}
            className={styles.announcementButton}
            preIcon={{ kind: "show_black_eye" }}
            variant="text"
            size="small"
          >
            Preview Announcement
          </Button>
        </div>
      </section>
      <section className={styles.container}>
        <div className={styles.row}>
          <div className={classNames(styles.column, styles.noWrap)}>
            <h2 className={styles.columnTitle}>Time Zone</h2>
            <p className={styles.columnDescription}>{timeZone.label}</p>
          </div>
          <div className={styles.column}>
            <h2 className={styles.columnTitle}>Deal Signing Territories</h2>
            <p className={styles.columnDescription}>
              {dealSigningTerritories?.map(t => t.label).join(", ") || "All"}
            </p>
          </div>
          <div className={classNames(styles.column, styles.statusContainer)}>
            <Status status={status} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <h2 className={styles.columnTitle}>Announcement Dates</h2>
            <div className={styles.columnDescription}>
              <p>{formattedStartTime}</p>
              <Icons className={styles.arrow} kind="arrow_right_big" />
              <p>{formattedEndTime}</p>
              <Duration endDate={endDate} startDate={startDate} />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <h2 className={styles.columnTitle}>Banner Message</h2>
            <p
              className={classNames(
                styles.columnDescription,
                styles.announcementMessage
              )}
            >
              {message}
            </p>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <Condition
              condition={!!link && !!href}
              Truthy={
                <>
                  <h2 className={styles.columnTitle}>Link</h2>
                  <div
                    className={classNames(
                      styles.columnDescription,
                      styles.linkContainer
                    )}
                  >
                    <p>{link}</p>
                    <span>
                      {"("}
                      <a
                        href={correctUrl(href ?? "")}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {href}
                      </a>
                      {")"}
                    </span>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AnnouncementReview;
