import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom-v5-compat";
import { useOktaAuth } from "@okta/okta-react";

import styles from "./ForgotPassword.module.scss";

import PageHeader from "../../components/shared/PageHeader";
import Button, { ButtonType } from "../../components/shared/UiButton";
import ResponseAlert from "../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../components/shared/UiResponseAlert/ResponseAlert";
import TextField from "../../components/shared/UiTextField";
import { axiosInstance } from "../../services/axios";
import { getErrorDetails } from "../../utils/error";
import { emailValidaton, requiredValidaton } from "../../utils/validation";
import { ForgotPasswordItem } from "./ForgotPassword.types";

/**
 * Renders forgot password page
 * @returns JSX element
 */
const ForgotPassword = (): JSX.Element => {
  const { authState } = useOktaAuth();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState<unknown>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const { control, formState, handleSubmit } = useForm<ForgotPasswordItem>({
    mode: "onChange"
  });

  const validateEmail = emailValidaton(t("inputs.invalidEmail"));
  const validateRequired = requiredValidaton(t("inputs.thisFieldIsRequired"));

  const errorRender = (err: unknown): JSX.Element => {
    const { errorCode, status } = getErrorDetails(err);
    if (status === 400 && errorCode === "WCP_E_110") {
      return (
        <Trans
          i18nKey="troubleToLoginPage.registrationError"
          components={{
            a: <Link className={styles.errorLink} to="/registration" />
          }}
          t={t}
        />
      );
    }
    if (status === 404) {
      return (
        <Trans
          i18nKey="troubleToLoginPage.errorMessage"
          components={{
            a: <Link className={styles.errorLink} to="/contact-us" />
          }}
          t={t}
        />
      );
    }
    return <>{t("common.unknownError")}</>;
  };

  const onSubmit = async (values: ForgotPasswordItem) => {
    setError(null);
    try {
      await axiosInstance.post("/api/users/external/reset-password", {
        email: values.email,
        language: i18n.language
      });
      setIsSuccess(true);
    } catch (e) {
      const { errorCode, status } = getErrorDetails(e);
      if (
        status === 400 &&
        errorCode === "WCP_E_119" &&
        process.env.REACT_APP_PASS_RESET_WMG_URI
      ) {
        window.open(process.env.REACT_APP_PASS_RESET_WMG_URI)?.focus();
        return;
      }
      if (
        status === 400 &&
        errorCode === "WCP_E_120" &&
        process.env.REACT_APP_PASS_RESET_ADA_URI
      ) {
        window.open(process.env.REACT_APP_PASS_RESET_ADA_URI)?.focus();
        return;
      }
      setError(e);
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      navigate("/");
    }
  }, [authState?.isAuthenticated, navigate]);

  if (isSuccess) {
    return (
      <div className="container">
        <div className={styles.success}>
          <PageHeader className={styles.h1} hasGoldBorder variant="h1">
            {t("troubleToLoginPage.successPopup.header")}
          </PageHeader>
          <div className={styles.description}>
            {t("troubleToLoginPage.successPopup.text")}
          </div>
          <Button
            className={styles.successCloseButton}
            onClick={() => navigate("/login")}
            type="button"
            variant={ButtonType.Primary}
          >
            {t("buttons.close")}
          </Button>
          <div className={styles.successInfo}>
            {t("common.moreQuestions.text1")}{" "}
            <Trans
              components={{
                a1: <Link className={styles.successLink} to="/faq" />,
                a2: <Link className={styles.successLink} to="/contact-us" />
              }}
              i18nKey="common.moreQuestions.text2"
              t={t}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <form className={styles.forgotPassword} onSubmit={handleSubmit(onSubmit)}>
        <PageHeader className={styles.h1} variant="h1">
          {t("troubleToLoginPage.troubleToLogin")}
        </PageHeader>
        <div className={styles.content}>
          <div className={styles.description}>
            {t("troubleToLoginPage.resetYourPassword")}
          </div>
          {error && (
            <ResponseAlert
              className={styles.alert}
              onClick={() => setError(null)}
              type={ResponseAlertType.Error}
            >
              {errorRender(error)}
            </ResponseAlert>
          )}
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.field}
                errors={{ email: !!error }}
                label={t("inputs.emailAddress")}
                onChange={e =>
                  field.onChange(e.target.value.replace(/\s/g, ""))
                }
                placeholder="user@example.com"
                type="text"
              />
            )}
            rules={{ validate: v => validateRequired(v) ?? validateEmail(v) }}
          />
          <div className={styles.toolbar}>
            <Link to="/registration">{t("loginPage.createNewAccount")}</Link>
            <div className={styles.toolbarMainActions}>
              <Button
                onClick={() => navigate(-1)}
                type="button"
                variant={ButtonType.Text}
              >
                {t("buttons.back")}
              </Button>
              <Button
                disabled={formState.isSubmitting || !formState.isValid}
                loading={formState.isSubmitting}
                type="submit"
                variant={ButtonType.Primary}
              >
                {t("buttons.reset")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
