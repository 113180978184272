import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import styles from "./CreatePassword.module.scss";

import Button, { ButtonType } from "../../../components/shared/UiButton";
import ResponseAlert from "../../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../../components/shared/UiResponseAlert/ResponseAlert";
import TextField from "../../../components/shared/UiTextField";
import { getUrlParams, textParams } from "../../../utils";
import SuccessModal from "./SuccessModal";

const CreatePassword = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isDirty, isValid }
  } = useForm({ mode: "all" });
  const password = useRef({});
  password.current = watch("password", "");
  const history = useHistory();
  const { token } = getUrlParams(history.location.search);
  const isCreate = history.location.pathname.includes("create-password");
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<null | boolean>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [status, setStatus] = useState(null);

  const errorMsg = (code?: string) => {
    switch (code) {
      case "E0000105":
        return "You have access an an account recovery link that has expired or been previously used.";
      default:
        return t(`resetPasswordPage.errorMessagePasswordRequirement`);
    }
  };
  const onSubmit = async ({ password, repeatPassword }: any) => {
    setIsLoading(true);
    setError(null);
    try {
      const requestPassword = password === repeatPassword ? password : null;
      if (requestPassword) {
        let res;
        if (!status && !isCreate) {
          res = await axios.put(
            `${process.env.REACT_APP_BASE_URI}api/users/external/reset-password/exchange-token?token=${token}`
          );
          setStatus(res.data);
        }
        const stateToken = await axios.post(
          `${process.env.REACT_APP_OKTA_PATH}api/v1/authn/recovery/token`,
          {
            recoveryToken: res?.data || status || token
          }
        );
        const url = `${process.env.REACT_APP_OKTA_PATH}api/v1/authn/credentials/reset_password`;
        const requestBody = isCreate
          ? {
              stateToken: stateToken.data.stateToken,
              newPassword: requestPassword,
              verifyPassword: requestPassword
            }
          : {
              newPassword: requestPassword,
              stateToken: stateToken.data.stateToken
            };
        const { data } = await axios.post(url, requestBody);
        if (data) {
          setIsSuccess(true);
        }
      } else {
        setError(t(`resetPasswordPage.errorMessagePasswordRequirement`));
      }
    } catch ({ response }) {
      const errors: any = response;
      setError(errorMsg(errors.data.errorCode));
    } finally {
      setIsLoading(false);
    }
  };

  return isSuccess ? (
    <SuccessModal isCreate={isCreate} />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.resetPassword}>
      <h3 className={styles.title}>
        {isCreate
          ? t(`resetPasswordPage.setPassword`)
          : t(`resetPasswordPage.resetPassword`)}
      </h3>
      {error && (
        <ResponseAlert
          className={styles.error}
          type={ResponseAlertType.Error}
          onClick={() => setError(null)}
        >
          <div>{error}</div>
        </ResponseAlert>
      )}
      <p className={styles.description}>{t(`resetPasswordPage.text`)}</p>
      <TextField
        type="password"
        label={t(`inputs.createPassword`)}
        className={styles.inputs}
        {...register("password", {
          required: "field is mandatory",
          minLength: {
            value: 8,
            message: "Password must have at least 8 characters"
          }
        })}
      />
      <TextField
        type="password"
        label={t(`inputs.confirmPassword`)}
        className={styles.inputs}
        {...register("repeatPassword", {
          required: "field is mandatory",
          validate: value =>
            value === password.current || "The passwords do not match"
        })}
      />
      <div className={styles.actions}>
        <div className={styles.links}>
          {isCreate && (
            <>
              {textParams(t(`resetPasswordPage.createPasswordText`))[0]}
              <Link to={"/terms-of-use"} className={styles.link}>
                {textParams(t(`resetPasswordPage.createPasswordText`))[1]}
              </Link>{" "}
              {textParams(t(`resetPasswordPage.createPasswordText`))[2]}{" "}
              <Link to={"/privacy-policy"} className={styles.link}>
                {textParams(t(`resetPasswordPage.createPasswordText`))[3]}
              </Link>
            </>
          )}
          {!isCreate && (
            <Trans
              components={{
                a1: <Link className={styles.link} to="/terms-of-use" />,
                a2: <Link className={styles.link} to="/privacy-policy" />
              }}
              i18nKey="resetPasswordPage.changePasswordText"
              t={t}
            />
          )}
        </div>
        <Button
          type={"submit"}
          variant={ButtonType.Primary}
          disabled={!isDirty || !isValid || !!isLoading}
          className={styles.buttonCreate}
        >
          {isCreate ? t(`buttons.create`) : t(`buttons.reset`)}
        </Button>
      </div>
    </form>
  );
};

export default CreatePassword;
