import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { axiosInstance } from "../../services/axios";
import { payeeCodeSelector } from "../../store/appStatic/selectors";
import { trackEvent } from "../../utils/analytics";
import { FETCH_INTERVAL } from "./AlertBanner.constants";
import { AlertBannerProps, AlertItemResponse } from "./AlertBanner.types";
import AlertCarousel from "./components/AlertCarousel";
import { AlertItem } from "./types/alertItem";
import { getAlertPreviewItems } from "./utils/alertPreview";

/**
 * Renders alert banner
 * @param props React component properties
 * @returns React component
 */
const AlertBanner: FC<AlertBannerProps> = props => {
  const payeeCode = useSelector<unknown, string | undefined>(payeeCodeSelector);

  const [items, setItems] = useState<AlertItem[]>([]);

  useEffect(() => {
    if (props.isPreview) {
      /**
       * Updates alert preview items from the local storage
       */
      const updateAlertPreviewItems = (): void => {
        const newItems = getAlertPreviewItems();
        setItems(newItems ?? []);
      };
      updateAlertPreviewItems();
      window.addEventListener("storage", updateAlertPreviewItems);
      return () => {
        window.removeEventListener("storage", updateAlertPreviewItems);
      };
    }

    const source = axios.CancelToken.source();
    /**
     * Fetches alerts from BE
     */
    const fetchAlerts = async (): Promise<void> => {
      try {
        const { data } = await axiosInstance.get<AlertItemResponse[]>(
          "/api/cms/announcements/active",
          {
            cancelToken: source.token,
            headers: payeeCode ? { "X-Payee-Code": payeeCode } : {}
          }
        );
        const newItems: AlertItem[] = data.map(i => ({
          endDate: i.endDate,
          id: i.id,
          link: i.href && i.link ? { href: i.href, text: i.link } : undefined,
          startDate: i.startDate,
          text: i.message
        }));
        setItems(newItems);
      } catch (e) {
        console.error(`Cannot load alerts: `, e);
      }
    };
    void fetchAlerts();
    const intervalId = setInterval(() => {
      void fetchAlerts();
    }, FETCH_INTERVAL);

    return () => {
      clearInterval(intervalId);
      source.cancel("Request cancelled by user");
    };
  }, [payeeCode, props.isPreview]);

  return (
    <AlertCarousel
      className={props.className}
      hasFullWidthText={props.hasFullWidthText}
      items={items}
      onSlideChange={() => trackEvent(true, "o", "Switch alert banner")}
    />
  );
};

export default AlertBanner;
