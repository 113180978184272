import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom-v5-compat";

import styles from "./ContactUs.module.scss";
import { ReactComponent as GlobeIcon } from "../../icons/globus.svg";

import PageHeader from "../../components/shared/PageHeader";
import Button, { ButtonType } from "../../components/shared/UiButton";
import ResponseAlert from "../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../components/shared/UiResponseAlert/ResponseAlert";
import UiSelect from "../../components/shared/UiSelect";
import TextAreaField from "../../components/shared/UiTextArea";
import TextField from "../../components/shared/UiTextField";
import { axiosInstance } from "../../services/axios";
import {
  countrySelector,
  profileSelector
} from "../../store/appStatic/selectors";
import { UserProfile } from "../../types/userProfile";
import { getUrlParams } from "../../utils";
import { emailValidaton, requiredValidaton } from "../../utils/validation";
import { contactUsQuestions } from "./ContactUs.constants";
import { ContactUsItem } from "./ContactUs.types";

/**
 * Renders contact us page
 * @returns JSX element
 */
const ContactUs = (): JSX.Element => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { control, formState, handleSubmit, reset } = useForm<ContactUsItem>({
    mode: "all"
  });

  const countries = useSelector(countrySelector);
  const profile = useSelector<unknown, UserProfile | null>(profileSelector);

  const [error, setError] = useState<null | string>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const impersonateId = window.localStorage.getItem("impersonateId");
  const impersonateUser = window.localStorage.getItem("impersonateUser")
    ? JSON.parse(window.localStorage.getItem("impersonateUser") ?? "")
    : null;
  const stateQueryParam = getUrlParams(location.search)?.state;
  const user = impersonateUser?.email ? impersonateUser : profile;
  const validateEmail = emailValidaton(t("inputs.invalidEmail"));
  const validateRequired = requiredValidaton(t("inputs.thisFieldIsRequired"));

  const questions = useMemo(() => {
    return contactUsQuestions.filter(q => {
      if (impersonateId && profile) {
        return q.postLogin && q.label !== "menu.deleteAccount";
      }
      return profile ? q.postLogin : q.preLogin;
    });
  }, [impersonateId, profile]);

  const onCancel = (): void => {
    if (location.key === "default") {
      navigate("/login");
      return;
    }
    navigate(-1);
  };

  const onContactUsLinkClick = (): void => {
    reset();
    setIsSuccess(false);
  };

  const onSubmit = async (data: ContactUsItem) => {
    setError(null);
    try {
      await axiosInstance.post(
        "/api/contact-us",
        {
          ...data,
          country: data.country.value,
          isOther: data.question.label === "contactUsPage.topics.other",
          languageCode: i18n.language,
          member: data.member.value ? t("common.yes") : t("common.no"),
          question: t(data.question.label)
        },
        { headers: { "Accept-Language": "en" } }
      );
      setIsSuccess(true);
    } catch {
      setError(t("resetPasswordPage.errorMessageUnknown"));
    }
  };

  useEffect(() => {
    if (!profile) {
      return;
    }
    const country = countries.find(c => c.label === user?.location);
    reset({
      email: user?.email ?? "",
      firstName: user?.firstName || "",
      member: { label: t("common.yes"), value: true },
      lastName: user?.lastName || "",
      ...(country && {
        country: { label: country?.label || "", value: country?.value || "" }
      }),
      ...(stateQueryParam === "delete" && {
        question: questions.find(q => q.label === "menu.deleteAccount")
      })
    });
  }, [countries, profile, stateQueryParam, reset, t, user]);

  if (isSuccess) {
    return (
      <div className="container">
        <div className={styles.success}>
          <PageHeader className={styles.h1} hasGoldBorder variant="h1">
            {t("contactUsPage.successPopup.header")}!
          </PageHeader>
          <p className={styles.successDescription}>
            {t("contactUsPage.successPopup.text")}
          </p>
          <Button
            className={styles.successCloseButton}
            onClick={() => navigate(-1)}
            type="button"
            variant={ButtonType.Primary}
          >
            {t("buttons.close")}
          </Button>
          <div className={styles.info}>
            {t("common.moreQuestions.text1")}{" "}
            <Trans
              components={{
                a1: <Link to="/faq" className={styles.infoLink} />,
                a2: (
                  <Link
                    className={styles.infoLink}
                    onClick={onContactUsLinkClick}
                    to="/contact-us"
                  />
                )
              }}
              i18nKey="common.moreQuestions.text2"
              t={t}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <PageHeader className={styles.h1} hasGoldBorder variant="h1">
          {t("contactUsPage.title")}
        </PageHeader>
        {error && (
          <ResponseAlert
            className={styles.alert}
            onClick={() => setError(null)}
            type={ResponseAlertType.Error}
          >
            <>{error}</>
          </ResponseAlert>
        )}
        <div className={styles.fieldsRow}>
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => (
              <TextField
                {...field}
                label={`${t("inputs.firstName")}*`}
                readOnly={!!profile && !!user?.firstName}
                type="text"
              />
            )}
            rules={{ validate: validateRequired }}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => (
              <TextField
                {...field}
                label={`${t("inputs.lastName")}*`}
                readOnly={!!profile && !!user?.lastName}
                type="text"
              />
            )}
            rules={{ validate: validateRequired }}
          />
        </div>
        <div className={styles.fieldsRow}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                {...field}
                label={`${t("inputs.email")}*`}
                readOnly={!!profile}
                placeholder={"user@example.com"}
                type="text"
              />
            )}
            rules={{ validate: v => validateRequired(v) ?? validateEmail(v) }}
          />
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <UiSelect
                {...field}
                disabled={
                  !!profile ||
                  (impersonateUser &&
                    !!countries.find(
                      c => c.label === impersonateUser?.location
                    ))
                }
                label={`${t("inputs.location")}*`}
                options={countries}
                placeholder={`--${t("inputs.selectorPlaceholder")}--`}
                rightIcon={<GlobeIcon />}
              />
            )}
            rules={{ validate: validateRequired }}
          />
        </div>
        <div className={styles.fieldsRow}>
          <Controller
            control={control}
            name="question"
            render={({ field }) => (
              <UiSelect
                {...field}
                getOptionLabel={(e: any) => t(e.label)}
                label={`${t("inputs.chooseTopic")}*`}
                options={questions}
                placeholder={t("inputs.chooseTopicPlaceholder")}
              />
            )}
            rules={{ validate: validateRequired }}
          />
          <Controller
            control={control}
            name="member"
            render={({ field }) => (
              <UiSelect
                {...field}
                disabled={!!profile}
                label={`${t("inputs.isMember")}*`}
                options={[
                  { label: t("common.yes"), value: true },
                  { label: t("common.no"), value: false }
                ]}
                placeholder={`--${t("inputs.selectorPlaceholder")}--`}
              />
            )}
            rules={{ validate: validateRequired }}
          />
        </div>
        <Controller
          control={control}
          name="comment"
          render={({ field }) => (
            <TextAreaField
              {...field}
              className={styles.textarea}
              errors={{}}
              label={t("inputs.message")}
            />
          )}
        />
        <div className={styles.toolbar}>
          <Button onClick={onCancel} type="button" variant={ButtonType.Text}>
            {t("buttons.cancel")}
          </Button>
          <Button
            disabled={formState.isSubmitting || !formState.isValid}
            loading={formState.isSubmitting}
            type="submit"
            variant={ButtonType.Primary}
          >
            {t("buttons.send")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
