import React, { JSX, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { components, IndicatorsContainerProps } from "react-select";
import { DateTime } from "luxon";

import styles from "./AnnouncementModal.module.scss";
import { ReactComponent as DownIcon } from "../../../../icons/iconDown.svg";
import { ReactComponent as UpIcon } from "../../../../icons/iconUp.svg";

import Condition from "../../../../components/shared/Condition";
import Icons from "../../../../components/shared/Icon";
import {
  Datepicker,
  Timepicker
} from "../../../../components/userManagement/Datepicker";
import { InputField } from "../../../../components/userManagement/InputField";
import SelectField from "../../../../components/userManagement/SelectField";
import Button from "../../../../components/userManagement/WCMButton";
import {
  convertUTCToZone,
  getTimeZones,
  roundMintuesTo
} from "../../../../utils/time";
import { Textarea } from "..";
import Duration from "../Duration";

type AnnouncementFormProps = {
  isEditing: boolean;
  territories: string[];
};

const AnnouncementForm: React.FC<AnnouncementFormProps> = ({
  isEditing,
  territories
}) => {
  const { control, setValue, unregister } = useFormContext();
  const { startDate, endDate, timeZone, withLink } = useWatch({ control });

  const minTime = useMemo(() => {
    if (!timeZone?.value) return;
    const time = convertUTCToZone(timeZone.value);
    return roundMintuesTo(30, time).toJSDate();
  }, [timeZone?.value]);

  const dropdownIndicatorRender = (
    props: IndicatorsContainerProps
  ): JSX.Element => {
    return (
      <components.IndicatorsContainer {...props}>
        {props.selectProps.menuIsOpen ? <UpIcon /> : <DownIcon />}
      </components.IndicatorsContainer>
    );
  };

  const territoriesOptions = useMemo(
    () => territories.map(t => ({ label: t, value: t })),
    [territories]
  );

  const getSelectedPeriod = (
    start: Date | null | undefined,
    end: Date | null | undefined
  ): number | undefined =>
    start && end
      ? (end.getTime() - start.getTime()) / 1000 / 60 / 60
      : undefined;

  const setPeriod = (period: number) => {
    if (!startDate) return;
    const startTime = DateTime.fromJSDate(startDate);
    const endTime = startTime.plus({ hours: period });
    setValue("endDate", endTime.toJSDate(), { shouldDirty: true });
  };

  return (
    <div className={styles.announcementForm}>
      <h1 className={styles.title}>
        {isEditing ? "Edit Announcement" : "Create Announcement"}
      </h1>
      <form action="">
        <section>
          <div className={styles.block}>
            <h2>Time Zone</h2>
            <div className={styles.noWrap}>
              <SelectField
                control={control}
                name="timeZone"
                options={getTimeZones()}
              />
            </div>
          </div>
          <div className={styles.block}>
            <h2>Deal Signing Territories</h2>
            <SelectField
              components={{ IndicatorsContainer: dropdownIndicatorRender }}
              control={control}
              isMulti
              name="dealSigningTerritories"
              options={territoriesOptions}
              placeholder="All"
            />
          </div>
        </section>
        <section>
          <div className={styles.block}>
            <h2 className={styles.spaceBetween}>
              Announcement Dates
              <Duration endDate={endDate} startDate={startDate} />
            </h2>
            <div className={styles.datesContainer}>
              <Datepicker
                control={control}
                minDate={minTime}
                rules={{ required: true }}
                commonPeriods={[1, 3, 24, 24 * 3]}
                handlePeriodClick={period => {
                  setPeriod(period);
                }}
                name="startDate"
                selectedPeriod={getSelectedPeriod(startDate, endDate)}
              />
              <Timepicker
                rules={{ required: true }}
                control={control}
                minDate={minTime}
                name="startDate"
              />

              <Icons className={styles.arrow} kind="arrow_right_big" />

              <Datepicker
                rules={{
                  required: true
                }}
                control={control}
                minDate={startDate}
                name="endDate"
              />
              <Timepicker
                rules={{ required: true }}
                control={control}
                minDate={startDate}
                name="endDate"
              />
            </div>
          </div>
        </section>
        <section className={styles.block}>
          <h2>Announcement Banner Message</h2>
          <div>
            <Textarea
              limit={200}
              rules={{ required: true }}
              control={control}
              name="message"
            />
          </div>
        </section>
        <section className={styles.block}>
          <h2>
            Link <span>Optional</span>
          </h2>
          <h3>Add a helpful link related to the announcement.</h3>
          <div className={styles.linkContainer}>
            <Condition
              Falsy={
                <Button
                  onClick={() => setValue("withLink", true)}
                  preIcon={{ kind: "plus_light" }}
                  size="small"
                >
                  Add Link
                </Button>
              }
              Truthy={
                <>
                  <InputField
                    limit={25}
                    rules={{ required: !!withLink && "Link is required" }}
                    control={control}
                    name="link"
                    placeholder="Enter Text"
                  />
                  <InputField
                    rules={{ required: !!withLink && "Href is required" }}
                    className={styles.linkValue}
                    control={control}
                    name="href"
                    placeholder="Enter Link"
                  />
                  <Button
                    kind="warning"
                    onClick={() => {
                      unregister(["link", "href"]);
                      setValue("withLink", false);
                    }}
                    variant={"text"}
                  >
                    Remove
                  </Button>
                </>
              }
              condition={withLink}
            />
          </div>
        </section>
      </form>
    </div>
  );
};

export default AnnouncementForm;
