import { DateTime } from "luxon";

import {
  convertUTCToZone,
  getCurrentTimeZoneOption,
  getTimeZones
} from "../../utils/time";
import { Announcement, AnnouncementFormValues } from "./Announcements.types";

export const deriveStatus = (
  startDate: string,
  endDate: string,
  timeZone?: string
) => {
  const now = timeZone ? convertUTCToZone(timeZone) : DateTime.local();
  const start = DateTime.fromJSDate(new Date(startDate));
  const end = DateTime.fromJSDate(new Date(endDate));

  if (now < start) {
    return "Scheduled";
  }
  if (now > end) {
    return "Expired";
  }
  return "Active";
};

export const mapResponseToAnnouncement = (
  data?: Announcement | null
): AnnouncementFormValues | false => {
  if (!data) return false;
  return {
    dealSigningTerritories: data.dealSigningTerritories?.map(v => ({
      label: v,
      value: v
    })),
    id: data.id,
    startDate: new Date(data.startDate),
    endDate: new Date(data.endDate),
    timeZone:
      getTimeZones().find(timeZone => timeZone.value === data.timeZone) ||
      getCurrentTimeZoneOption(),
    message: data.message,
    href: data.href,
    link: data.link,
    withLink: !!data.href
  };
};

export const parceAnnouncmentDatesToTimeZone = (announcement: Announcement) => {
  const { startDate, endDate, timeZone } = announcement;
  if (!startDate || !endDate || !timeZone) return null;
  return {
    ...announcement,
    startDate: convertUTCToZone(timeZone, new Date(startDate)).toISO(),
    endDate: convertUTCToZone(timeZone, new Date(endDate)).toISO()
  } as Announcement;
};
