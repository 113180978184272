// The hook represents coolection of CRUD api to manage announcements
import { useCallback, useState } from "react";

import { axiosInstance } from "../../../services/axios";
import {
  Announcement,
  CreateAnnouncement,
  EndAnnouncement,
  GetAnnouncemnt,
  UpdateAnnouncement
} from "../Announcements.types";

const useAnnouncements = () => {
  const options = {
    take: 10000,
    skip: 0
  };

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [isAnnouncementsLoading, setIsAnnouncementsLoading] = useState(false);
  const [territories, setTerritories] = useState<string[]>([]);

  const getAnnouncements: GetAnnouncemnt = useCallback(async () => {
    setIsAnnouncementsLoading(true);
    const { data } = await axiosInstance.post("/api/cms/announcements/fetch", {
      ...options
    });
    const result = data.result.filter(
      (announcement: Announcement) => !announcement.deleted
    );
    setAnnouncements(result);
    setIsAnnouncementsLoading(false);
    return result;
  }, [setAnnouncements]);

  const getTerritories = useCallback(async () => {
    const { data } = await axiosInstance.get<string[]>(
      "/api/royalty/deal-signing-territories"
    );
    setTerritories(data);
    return data;
  }, []);

  const createAnnouncement: CreateAnnouncement = useCallback(
    async (announcemnt: Announcement) => {
      await axiosInstance.post("/api/cms/announcements", announcemnt);
      return getAnnouncements;
    },
    [getAnnouncements]
  );

  const updateAnnouncement: UpdateAnnouncement = useCallback(
    async (announcement: Announcement) => {
      if (!announcement) throw new Error("No announcement to update");
      await axiosInstance.put(
        `/api/cms/announcements/${announcement.id}`,
        announcement
      );
      return getAnnouncements;
    },
    [getAnnouncements]
  );

  const endAnnouncement: EndAnnouncement = useCallback(
    async (announcementId: string) => {
      if (!announcementId) throw new Error("Announcement id is required");
      await axiosInstance.put(`/api/cms/announcements/${announcementId}/end`);
      return getAnnouncements;
    },
    [getAnnouncements]
  );

  const deleteAnnouncement = useCallback(
    async (announcementId: string) => {
      if (!announcementId) throw new Error("Announcement id is required");
      await axiosInstance.delete(`/api/cms/announcements/${announcementId}`);
      return getAnnouncements;
    },
    [getAnnouncements]
  );

  return {
    announcements,
    isAnnouncementsLoading,
    getAnnouncements,
    getTerritories,
    createAnnouncement,
    updateAnnouncement,
    endAnnouncement,
    deleteAnnouncement,
    territories
  };
};

export default useAnnouncements;
