import { JSX, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom-v5-compat";
import axios from "axios";

import styles from "./ChangePassword.module.scss";

import PageHeader from "../../components/shared/PageHeader";
import Button, { ButtonType } from "../../components/shared/UiButton";
import ResponseAlert from "../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../components/shared/UiResponseAlert/ResponseAlert";
import TextField from "../../components/shared/UiTextField";
import { axiosInstance } from "../../services/axios";
import { profileSelector } from "../../store/appStatic/selectors";
import { UserProfile } from "../../types/userProfile";
import { getErrorDetails } from "../../utils/error";
import { confirmValidaton, requiredValidaton } from "../../utils/validation";
import { ChangePasswordFormItem } from "./ChangePassword.types";

const ChangePassword = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const profile = useSelector<unknown, UserProfile | null>(profileSelector);

  const { control, formState, handleSubmit } = useForm<ChangePasswordFormItem>({
    mode: "all"
  });
  const password = useWatch({ control, name: "password" });

  const [error, setError] = useState<null | string>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const tokenRef = useRef<string>("");

  const impersonateEmail = localStorage.getItem("impersonateEmail");
  const validatePasswordConfirm = confirmValidaton(
    "The passwords do not match",
    password
  );
  const validateRequired = requiredValidaton(t("inputs.thisFieldIsRequired"));

  const onSubmit = async (values: ChangePasswordFormItem) => {
    const newPassword = values.password;
    setError(null);

    // Check if token is already received
    if (!tokenRef.current) {
      try {
        const { data } = await axiosInstance.post<string>(
          "/api/users/external/change-password",
          {
            email: impersonateEmail || profile?.email
          }
        );
        tokenRef.current = data;
      } catch (e) {
        const { errorCode, status } = getErrorDetails(e);
        if (
          status === 400 &&
          errorCode === "WCP_E_119" &&
          process.env.REACT_APP_PASS_RESET_WMG_URI
        ) {
          window.open(process.env.REACT_APP_PASS_RESET_WMG_URI)?.focus();
          return;
        }
        if (
          status === 400 &&
          errorCode === "WCP_E_120" &&
          process.env.REACT_APP_PASS_RESET_ADA_URI
        ) {
          window.open(process.env.REACT_APP_PASS_RESET_ADA_URI)?.focus();
          return;
        }
        if (status !== 200) {
          setError(t("resetPasswordPage.errorMessageUnknown"));
          return;
        }
      }
    }

    // Use token
    try {
      const recoveryTokenResponse = await axios.post<{ stateToken: string }>(
        `${process.env.REACT_APP_OKTA_PATH}api/v1/authn/recovery/token`,
        { recoveryToken: tokenRef.current }
      );
      await axios.post<unknown>(
        `${process.env.REACT_APP_OKTA_PATH}api/v1/authn/credentials/reset_password`,
        { newPassword, stateToken: recoveryTokenResponse.data.stateToken }
      );
      setIsSuccess(true);
    } catch {
      tokenRef.current = "";
      setError(t("resetPasswordPage.errorMessagePasswordRequirement"));
    }
  };

  if (isSuccess) {
    return (
      <div className="container">
        <div className={styles.success}>
          <PageHeader hasGoldBorder variant="h2">
            {t("resetPasswordPage.successMessage")}
          </PageHeader>
          <Button
            className={styles.successCloseButton}
            onClick={() => navigate("/profile")}
            type="button"
            variant={ButtonType.Primary}
          >
            {t("profilePage.userProfile")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <PageHeader className={styles.h2} variant="h2">
            {t("resetPasswordPage.resetPassword")}
          </PageHeader>
          {error && (
            <ResponseAlert
              className={styles.error}
              onClick={() => setError(null)}
              type={ResponseAlertType.Error}
            >
              <>{error}</>
            </ResponseAlert>
          )}
          <p className={styles.description}>{t("resetPasswordPage.text")}</p>
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.password}
                isReservedErrorSpace
                label={t("inputs.createPassword")}
                type="password"
              />
            )}
            rules={{
              minLength: {
                message: "Password must have at least 8 characters",
                value: 8
              },
              validate: validateRequired
            }}
          />
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.confirmPassword}
                isReservedErrorSpace
                label={t("inputs.confirmPassword")}
                type="password"
              />
            )}
            rules={{ validate: validatePasswordConfirm }}
          />
          <div className={styles.actions}>
            <div>
              <Trans
                components={{
                  a1: <Link className={styles.link} to="/terms-of-use" />,
                  a2: <Link className={styles.link} to="/privacy-policy" />
                }}
                i18nKey="resetPasswordPage.changePasswordText"
                t={t}
              />
            </div>
            <Button
              disabled={formState.isSubmitting || !formState.isValid}
              loading={formState.isSubmitting}
              type="submit"
              variant={ButtonType.Primary}
            >
              {t("buttons.reset")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
