import { ContactUsQuestion } from "./ContactUs.types";

export const contactUsQuestions: ContactUsQuestion[] = [
  {
    label: "contactUsPage.topics.troubleLoggingIn",
    postLogin: false,
    preLogin: true,
    value: 1
  },
  {
    label: "contactUsPage.topics.registerAccount",
    postLogin: false,
    preLogin: true,
    value: 2
  },
  {
    label: "contactUsPage.topics.payments",
    postLogin: true,
    preLogin: true,
    value: 3
  },
  {
    label: "contactUsPage.topics.updateAccount",
    postLogin: true,
    preLogin: true,
    value: 4
  },
  {
    label: "contactUsPage.topics.royaltyStatements",
    postLogin: true,
    preLogin: true,
    value: 5
  },
  {
    label: "contactUsPage.topics.other",
    postLogin: true,
    preLogin: true,
    value: 6
  },
  {
    label: "menu.deleteAccount",
    postLogin: true,
    preLogin: false,
    value: 7
  }
];
