import { NavigateFunction } from "react-router-dom-v5-compat";

export enum AppStaticTypes {
  GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS",
  APP_STATIC_ERROR = "APP_STATIC_ERROR",
  APP_STATIC_LOADING = "ARCHITECT_USERS_LOADING",
  PROFILE_ACTION = "PROFILE_ACTION",
  PROFILE_ACTION_SUCCESS = "PROFILE_ACTION_SUCCESS",
  RESET_PROFILE_STATE = "PROFILE_BE_ACTION_SUCCESS",
  SET_SINGLE_CLIENT = "SET_SINGLE_CLIENT",
  CLEAR_CLIENT = "CLEAR_CLIENT",
  CHANGE_LANGUAGE_SUCCESS_ACTION = "CHANGE_LANGUAGE_SUCCESS_ACTION",
  CHANGE_IS_TERM_ACCEPTED_ACTION = "CHANGE_IS_TERM_ACCEPTED_ACTION",
  CHANGE_EMAIL_NOTIFICATION_SETTING_ACTION = "CHANGE_EMAIL_NOTIFICATION_SETTING_ACTION",
  GET_LANGUAGE_ACTION = "GET_LANGUAGE_ACTION",
  GET_LANGUAGE_SUCCESS_ACTION = "GET_LANGUAGE_SUCCESS_ACTION",
  SET_COUNTRY_CODE = "SET_COUNTRY_CODE",
  SET_PAYEE_CODE = "SET_PAYEE_CODE",
  SET_PRESELECTED_PAYEE_CODE = "SET_PRESELECTED_PAYEE_CODE"
}

type payeeAccountsType = {
  payeeAccount: string;
  payeeCode: string;
  accountingPeriod: number;
  settlementPeriod: number;
};
type addressType = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
};

export interface IClientsState {
  fullName: string;
  alternativeName: null | string;
  suisaIpiNumber: string;
  affiliatedSociety: string;
  phoneNumber: null | string | number;
  address: addressType;
  payeeAccounts: payeeAccountsType[];
  slug: string;
  emailType: string[];
}

export interface IProfileState {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  location: string;
  countryCode: string;
  status: string;
  role: string;
  type: string;
  songwriterType: string;
  lastActive: string;
  modifiedAt: string;
  modifiedBy: string;
  activities: string[];
  isSuperAdmin?: boolean;
  settings: {
    id: string;
    userId: string;
    preferableLanguage: string;
    modifiedBy: string;
    modifiedAt: string;
  };
}

export interface ISingleClientState {
  fullName: string;
  alternativeName: null | string;
  suisaIpiNumber: string;
  affiliatedSociety: string;
  phoneNumber: null | string;
  address: addressType;
  payeeAccounts: payeeAccountsType[];
  slug: string;
  emailType: string[];
}

export interface IStaticState {
  readonly preselectedPayeeCode: string;
  readonly payeeCode: string;
  readonly country: { code: string; name: string }[];
  readonly errors: string[];
  readonly languages: { code: string; name: string }[];
  readonly clients: IClientsState[];
  readonly clientEmailType: string[];
  readonly profile: any;
  readonly error: string;
  readonly countryCode: string;
  readonly loading: boolean;
  readonly impersonateLn: string;
  readonly singleClient: { data: ISingleClientState | null };
}

export interface ISetLoadingAction {
  type: AppStaticTypes.APP_STATIC_LOADING;
  payload: boolean;
}

export interface ISetErrorAction {
  type: AppStaticTypes.APP_STATIC_ERROR;
  payload: string[];
}

export interface ISetProfileAction {
  type: AppStaticTypes.PROFILE_ACTION;
  payload: {
    navigate: NavigateFunction;
    id?: string;
    shouldAutoSwitchLanguage?: boolean;
  };
}

export type profileSuccessType = {
  data: IProfileState | null;
  clients: IClientsState[];
  emailType: string[];
  preferableLanguage: string;
  email: string | undefined;
  profile: IProfileState | null;
};

export interface ISetProfileSuccessAction {
  type: AppStaticTypes.PROFILE_ACTION_SUCCESS;
  payload: profileSuccessType;
}

export interface IResetProfileAction {
  type: AppStaticTypes.RESET_PROFILE_STATE;
}

export interface ISetSingleClient {
  type: AppStaticTypes.SET_SINGLE_CLIENT;
  payload: { data: ISingleClientState | null };
}

export interface IGetLanguageAction {
  type: AppStaticTypes.GET_LANGUAGE_ACTION;
  payload: string;
}

export interface IGetLanguageSuccessAction {
  type: AppStaticTypes.GET_LANGUAGE_SUCCESS_ACTION;
  payload: string;
}

export interface ISetPayeeCodeAction {
  type: AppStaticTypes.SET_PAYEE_CODE;
  payload: string;
}

export interface IChangeLanguageSuccessAction {
  type: AppStaticTypes.CHANGE_LANGUAGE_SUCCESS_ACTION;
  payload: { data: { preferableLanguage: string } };
}

export interface IClearClientState {
  type: AppStaticTypes.CLEAR_CLIENT;
}

export type IStaticActions =
  | IClearClientState
  | IChangeLanguageSuccessAction
  | IGetLanguageSuccessAction
  | ISetSingleClient
  | IResetProfileAction
  | ISetProfileSuccessAction
  | ISetLoadingAction
  | ISetErrorAction
  | IGetLanguageAction
  | ISetPayeeCodeAction
  | any;
